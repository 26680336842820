import React from "react";
import { Link } from "gatsby";
import styled from "@emotion/styled";
import colors from "styles/colors";
import spooch from "images/carolstran.png"
import { OutboundLink } from "gatsby-plugin-google-analytics"

const FooterContainer = styled("div")`
    padding-top: 2em;
    padding-bottom: 3em;
    display: flex;
    flex-direction: column;
    align-items: center;

    svg {
        max-width: 50px;
    }
`

const FooterAuthor = styled(OutboundLink)`
    font-size: 0.75em;
    color: ${colors.grey700};
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    margin-top: 1.5em;

    &:hover {
        color: ${colors.blue900};
   }
`

const SpoochWrapper = styled(Link)`
&:hover {
    .FooterSpooch {
        animation-name: rotate;
        animation-duration: 1.5s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }

    @keyframes rotate {
     from {transform: rotate(0deg);}
     to {transform: rotate(360deg);}
    }
}
`

const FooterSpooch = styled("img")`
    max-width: 33px;
    margin-top: 0.5em;
`

const Footer = () => (
    <FooterContainer>
        <FooterAuthor href="https://github.com/margueriteroth">
            Design and Gatsby starter by Marguerite Roth
        </FooterAuthor>
        <SpoochWrapper to="/">
            <FooterSpooch aria-label="Go to homepage" className="FooterSpooch" src={spooch} />
        </SpoochWrapper>
    </FooterContainer>
)

export default Footer;
